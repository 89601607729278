import React from "react";
import { useTranslation } from "react-i18next";

const AboutUs = () => {
  const { t } = useTranslation();

  return (
    <div className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
          <p className="text-base/7 font-semibold text-indigo-600">{t("aboutUs.tagline")}</p>
          <h1 className="mt-2 text-pretty text-4xl font-semibold tracking-tight text-gray-900 sm:text-5xl">
            {t("aboutUs.title")}
          </h1>
          <div className="mt-10 grid max-w-xl grid-cols-1 gap-8 text-base/7 text-gray-700 lg:max-w-none lg:grid-cols-2">
            <div>
              <p>{t("aboutUs.paragraph1")}</p>
              <p className="mt-8">{t("aboutUs.paragraph2")}</p>
            </div>
            <div>
              <p>{t("aboutUs.paragraph3")}</p>
              <p className="mt-8">{t("aboutUs.paragraph4")}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;