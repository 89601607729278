// src/i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import en from './locales/en.json';
import ru from './locales/ru.json';
import id from './locales/id.json';
import ko from './locales/ko.json';
import ja from './locales/ja.json';
import vi from './locales/vi.json';
import zh from './locales/zh.json';
import yue from './locales/yue.json';
import th from './locales/th.json'; // Import Thai language file

const resources = {
  en: { translation: en },
  ru: { translation: ru },
  id: { translation: id },
  ko: { translation: ko },
  ja: { translation: ja },
  vi: { translation: vi },
  zh: { translation: zh },
  yue: { translation: yue },
  th: { translation: th }, // Add Thai translations
};

i18n.use(initReactI18next).init({
  resources,
  lng: 'en', // Default language
  fallbackLng: 'en', // Fallback language
  interpolation: { escapeValue: false }, // React already escapes by default
  supportedLngs: Object.keys(resources), // Automatically support all languages in resources
});

export default i18n;