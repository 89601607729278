import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

import Nav from './components/Header';
import Hero from './components/Hero';
import Gallery from './components/Gallery';
import AboutUs from './components/AboutUs';
import Footer from './components/Footer';
import FindUs from './components/FindUs';

import bannerImage from './img/banner.jpg'; // Import banner image

const SUPPORTED_LANGUAGES = ['en', 'ru', 'id', 'ko', 'ja', 'th', 'vi', 'zh', 'yue'];

const App = () => (
  <Router>
    <Routes>
      <Route path="/" element={<Navigate to="/en" />} />
      <Route path="/:lang" element={<MainPage />} />
    </Routes>
  </Router>
);

const MainPage = () => {
  const { i18n, t } = useTranslation();
  const { lang } = useParams();

  React.useEffect(() => {
    if (SUPPORTED_LANGUAGES.includes(lang)) {
      i18n.changeLanguage(lang);
    } else {
      i18n.changeLanguage('en'); // Default fallback language
    }
  }, [lang, i18n]);

  return (
    <>
      <Helmet>
        <html lang={lang} />
        <title>{t('meta.title')}</title>
        <meta name="description" content={t('meta.description')} />
        <meta name="keywords" content={t('meta.keywords', 'default keywords for Chopaka Shop')} />
        <meta name="author" content={t('meta.author', 'Chopaka Shop')} />

        {/* Open Graph */}
        <meta property="og:title" content={t('meta.title')} />
        <meta property="og:description" content={t('meta.description')} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={`https://chopakashop.com/${lang}`} />
        <meta property="og:image" content={bannerImage} />

        {/* Twitter */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={t('meta.title')} />
        <meta name="twitter:description" content={t('meta.description')} />
        <meta name="twitter:image" content={bannerImage} />

        {/* Canonical and Alternate Links */}
        <link rel="canonical" href={`https://chopakashop.com/${lang}`} />
        {SUPPORTED_LANGUAGES.map((language) => (
          <link
            key={language}
            rel="alternate"
            hrefLang={language}
            href={`https://chopakashop.com/${language}`}
          />
        ))}
      </Helmet>
      <Nav logo="path-to-logo" />
      <Hero />
      <AboutUs />
      <Gallery />
      <FindUs />
      <Footer />
    </>
  );
};

export default App;