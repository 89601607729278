import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

const Header = () => {
  const { t, i18n } = useTranslation();
  const { lang } = useParams();
  const navigate = useNavigate();

  const languages = [
    { code: "id", label: "Bahasa Indonesia" },
    { code: "en", label: "English" },
    { code: "ja", label: "日本語" },
    { code: "ko", label: "한국어" },
    { code: "ru", label: "Русский" },
    { code: "th", label: "ไทย" },
    { code: "vi", label: "Tiếng Việt" },
    { code: "zh", label: "中文" },
    { code: "yue", label: "粵語" },
  ];

  useEffect(() => {
    // Detect browser or computer language
    const browserLang = navigator.language.slice(0, 2); // Extract first two letters of the language
    if (!lang && languages.some((language) => language.code === browserLang)) {
      i18n.changeLanguage(browserLang);
      navigate(`/${browserLang}`);
    } else if (!lang) {
      i18n.changeLanguage("en");
      navigate("/en");
    } else {
      i18n.changeLanguage(lang);
    }
  }, [lang, i18n, navigate]);

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
    navigate(`/${language}`);
  };

  return (
    <header className="bg-white">
      <nav
        className="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8"
        aria-label="Global"
      >
        <div className="flex lg:flex-1">
          <a href={`/${lang || "en"}`} className="-m-1.5 p-1.5">
            <span className="sr-only">{t("nav.shopName")}</span>
            <img
              className="h-8 w-auto"
              src="https://res.cloudinary.com/bettercast/image/upload/c_thumb,w_200,g_face/v1706084822/chopakagummies/Chopakalogo_f7yfk3.webp"
              alt={t("nav.shopName")}
            />
          </a>
        </div>
        <div className="relative">
          <select
            value={i18n.language}
            onChange={(e) => changeLanguage(e.target.value)}
            className="block w-full rounded-md border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
          >
            {languages.map((language) => (
              <option key={language.code} value={language.code}>
                {language.label}
              </option>
            ))}
          </select>
        </div>
      </nav>
    </header>
  );
};

export default Header;